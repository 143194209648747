export const getServiceTags = tags => {
  return tags
    .filter(tag => tag.name.startsWith("$"))
    .reduce((acc, tag) => {
      const [name, value] = tag.name.split(":");
      const clearedName = name.replace("$", "");
      acc[clearedName] = value;
      return acc;
    }, {});
};

export const getPrimaryTag = tags => {
  return tags.filter(tag => !tag.name.startsWith("$"))[0];
};

import * as React from "react";
import styled from "styled-components";
import { Link } from "../Common";
import PropTypes from "prop-types";
import { getPrimaryTag } from "./Utils";
import { replaceHttpWithHttps } from "../../utils";
import theme from "src/components/theme";
import { fonts } from "../../theme/variables";

const PictureContainer = styled.div`
  height: 230px;
  position: relative;
`;

const Picture = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  display: block;
`;

const PageLink = styled(Link)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  font-weight: normal;
`;

const Container = styled.article`
  flex: 1 1 330px;
  overflow: hidden;
  min-height: 300px;
  background-color: ${theme.titleColor};
  border-radius: 6px;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.1);
  margin: 0 10px 40px;

  transition: transform 0.5s;

  &:hover {
    transform: scale(1.03);
  }

  @media (max-width: ${theme.mobileBreakpoint}) {
    flex: 1 1 auto;
    margin: 0 0 30px;
  }

  @media (min-width: ${theme.mobileBreakpoint}) {
    ${p =>
      p.firstChildBig &&
      `
      &:first-child {
        flex: 1 1 100%;
        max-width: 100%;

        ${PageLink} {
          flex-direction: row;
        }

        ${PictureContainer} {
          height: 100%;
          width: 66%;
        }
      }
    `};
  }
`;

const Details = styled.div`
  padding: 25px 36px 30px;
  overflow: hidden;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

const Subtitle = styled.div`
  font-size: 12px;
  color: ${theme.subtitleColor};
  text-transform: uppercase;
  font-weight: 500;
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: bold;
  line-height: 1.45;
  letter-spacing: -0.1px;
  color: ${theme.backgroundColor};
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Excerpt = styled.div`
  flex: auto;
  margin-top: 10px;
  font-size: 18px;
  color: ${theme.blogTextColor};
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 140px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  font-family: ${fonts.secondary}, "Times New Roman", Times, serif;
  -webkit-font-smoothing: antialiased;
`;

const PostMetaContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 36px;

  b {
    font-size: 14px;
    font-weight: bold;
    line-height: 2;
    color: ${theme.backgroundColor};
  }
`;

const AuthorAvatar = styled.img`
  display: inline-block;
  width: 28px;
  height: 28px;
  background: #e3e9ed;
  border-radius: 100%;
  object-fit: cover;
  margin-right: 10px;
`;

export const PostPreview = ({ post, className, firstChildBig }) => {
  const primaryTag = getPrimaryTag(post.tags);

  return (
    <Container firstChildBig={firstChildBig} className={className}>
      <PageLink href={`/blog/${post.slug}`}>
        {post.feature_image && (
          <PictureContainer>
            <Picture src={replaceHttpWithHttps(post.feature_image)} alt={post.title} />
          </PictureContainer>
        )}
        <Details>
          <div>
            <Subtitle>{primaryTag && primaryTag.name}</Subtitle>
            <Title>{post.title}</Title>
            <Excerpt>{post.custom_excerpt || post.excerpt}</Excerpt>
          </div>
          <PostMetaContainer>
            {post.primary_author.profile_image && (
              <AuthorAvatar
                src={replaceHttpWithHttps(post.primary_author.profile_image)}
                title={post.primary_author.name}
              />
            )}
          </PostMetaContainer>
        </Details>
      </PageLink>
    </Container>
  );
};

PostPreview.propTypes = {
  post: PropTypes.shape({
    title: PropTypes.string.isRequired,
    feature_image: PropTypes.string,
    featured: PropTypes.bool,
    primary_tag: PropTypes.shape({
      name: PropTypes.string,
    }),
    excerpt: PropTypes.string.isRequired,
    custom_excerpt: PropTypes.string,
    primary_author: PropTypes.shape({
      name: PropTypes.string.isRequired,
      profile_image: PropTypes.string,
    }).isRequired,
    slug: PropTypes.string,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      }),
    ),
  }),
  firstChildBig: PropTypes.bool,
  className: PropTypes.string,
};

import * as React from "react";
import styled from "styled-components";
import { Link } from "../Common";
import PropTypes from "prop-types";
import theme from "src/components/theme";

const PaginationContainer = styled.nav`
  flex: 1 1 100%;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  color: ${theme.subtitleColor};
`;

const PageLink = styled(Link)`
  margin: 0 30px;
  font-size: 22px;
  font-weight: normal;
  opacity: 0.7;
  color: ${theme.subtitleColor};
`;

export const Pagination = ({ pageContext }) => {
  const { previousPagePath, nextPagePath, humanPageNumber, numberOfPages } = pageContext;

  return (
    <PaginationContainer role="navigation">
      {previousPagePath && (
        <PageLink href={previousPagePath} rel="prev">
          Previous
        </PageLink>
      )}
      {numberOfPages > 1 && (
        <div className="pagination-location">
          Page {humanPageNumber} of {numberOfPages}
        </div>
      )}
      {nextPagePath && (
        <PageLink href={nextPagePath} rel="next">
          Next
        </PageLink>
      )}
    </PaginationContainer>
  );
};

Pagination.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

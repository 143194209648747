import * as React from "react";
import ReactHelmet from "react-helmet";
import PropTypes from "prop-types";
import { replaceHttpWithHttps } from "../../utils";

export const ImageMeta = ({ image }) => {
  if (!image) {
    return null;
  }

  const shareImageWithSSL = replaceHttpWithHttps(image);

  return (
    <ReactHelmet>
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={shareImageWithSSL} />
      <meta property="og:image" content={shareImageWithSSL} />
    </ReactHelmet>
  );
};

ImageMeta.propTypes = {
  image: PropTypes.string,
};

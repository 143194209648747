import * as React from "react";
import ReactHelmet from "react-helmet";
import { ImageMeta } from "./ImageMeta";
import { StaticQuery, graphql } from "gatsby";

export const BlogIndexMeta = () => {
  return (
    <StaticQuery
      query={graphql`
        query GhostSettingsWebsiteMeta {
          allGhostSettings {
            edges {
              node {
                ...GhostSettingsFields
              }
            }
          }
        }
      `}
      render={data => {
        const settings = data.allGhostSettings.edges[0].node;
        const shareImage = settings.cover_image;
        const description = settings.description || "AppSpector blog posts";
        const title = settings.title || "Blog";

        return (
          <>
            <ReactHelmet title={title}>
              <meta property="og:title" content={`AppSpector | ${title}`} />
              <meta name="twitter:title" content={`AppSpector | ${title}`} />
              <meta name="description" content={description} />
              <meta property="og:description" content={description} />
              <meta name="twitter:description" content={description} />
            </ReactHelmet>
            <ImageMeta image={shareImage} />
          </>
        );
      }}
    />
  );
};

import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { HeaderSectionContainer } from "../components/Header";
import { PostPreview } from "../components/blog/PostPreview";
import PropTypes from "prop-types";
import { Pagination } from "../components/blog/Pagination";
import { BlogIndexMeta } from "../components/meta/BlogIndexMeta";
import Header from "src/elements/Header";
import Footer from "src/elements/Footer";
import theme from "src/components/theme";

const HeaderContainer = styled.div`
  flex-direction: column;
  align-items: center;
  align-self: center;
  padding: 0 16px 0 16px;
  height: 200px;
  width: 100%;
  display: flex;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.69;
  color: ${theme.titleColor};

  @media (min-width: ${theme.mobileBreakpoint}) {
    height: 400px;
  }

  .gatsby-image-wrapper {
    margin-top: 12px;
    margin-bottom: 12px;
    max-height: 120px;
    max-width: 140px;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0 16px;
  background-color: ${theme.blogMainBackgroundColor};

  @media (min-width: ${theme.mobileBreakpoint}) {
    padding: 0 40px;
  }
`;

const FloatingContainer = styled.ul`
  margin: 0;
  flex: initial;
  display: flex;
  flex-flow: row wrap;
  overflow-x: visible;
  justify-content: space-between;
  list-style-type: none;
  max-width: ${theme.readableMaxWidth};
  padding: 30px 0;
  width: 100%;
  max-width: 1050px;

  @media (min-width: ${theme.mobileBreakpoint}) {
    padding-top: 0;
    transform: translateY(-108px);
  }
`;

const HeaderWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

const BlogIndex = ({ data, pageContext }) => {
  const posts = data.ghostPosts.edges;

  if (posts.length === 0) {
    // TODO: render empty
    return "No posts";
  }

  return (
    <Layout header={null} skipMeta={true} footer={<Footer />}>
      <BlogIndexMeta />
      <HeaderSectionContainer>
        <HeaderWrapper>
          <Header />
        </HeaderWrapper>
        <HeaderContainer>
          <Img fixed={data.duckImage.childImageSharp.fixed} />
          AppSpector Engineering Blog
        </HeaderContainer>
      </HeaderSectionContainer>
      <ContentContainer>
        <FloatingContainer>
          {posts.map(postData => (
            <PostPreview key={postData.node.uuid} post={postData.node} firstChildBig />
          ))}
          <Pagination pageContext={pageContext} />
        </FloatingContainer>
      </ContentContainer>
    </Layout>
  );
};

BlogIndex.propTypes = {
  data: PropTypes.shape({
    ghostPosts: PropTypes.object.isRequired,
    duckImage: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.object,
};

export default BlogIndex;

export const pageQuery = graphql`
  query BlogIndexQuery($limit: Int!, $skip: Int!) {
    ghostPosts: allGhostPost(sort: { order: DESC, fields: [published_at] }, limit: $limit, skip: $skip) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
    duckImage: file(relativePath: { eq: "labduck.png" }) {
      childImageSharp {
        fixed(height: 180) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`;
